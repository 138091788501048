<template>
  <section
    id="formosa"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/Formosa 1867_6.jpg')"
          height="100%"
        />
      </v-col>
      <v-col
        class="primary text-center pa-5 white--text"
        cols="12"
        md="6"
      >
        <base-heading>
          Formosa 1867<br>(Taiwanese Drama)<br>
          斯卡羅
        </base-heading>

        <base-text class="mb-5 text-left">
          <p>現役の医師として活躍する小説家、陳耀昌氏の同名小説を原作としたもので、歴史的な事件を通して先住民族の娘である蝶妹とルジャンドルのラブロマンスを描いたドラマ。</p>
        </base-text>

        <v-card color="secondary">
          <v-container class="pa-2">
            <v-row>
              <v-col cols="12">
                <v-img
                  :src="require(`@/assets/Formosa 1867_4.jpg`)"
                  max-height="500"
                />
              </v-col>
              <v-col cols="12">
                <v-img
                  :src="require(`@/assets/Formosa 1867_5.jpg`)"
                  max-height="500"
                />
              </v-col>
              <v-col cols="4">
                <v-img
                  :src="require(`@/assets/Formosa 1867_1.jpg`)"
                  max-height="400"
                />
              </v-col>
              <v-col cols="4">
                <v-img
                  :src="require(`@/assets/Formosa 1867_2.jpg`)"
                  max-height="400"
                />
              </v-col>
              <v-col cols="4">
                <v-img
                  :src="require(`@/assets/Formosa 1867_3.jpg`)"
                  max-height="400"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'Formosa1867',
  }
</script>
